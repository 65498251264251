const API_KEY = 'AIzaSyCafE18_haaIiHd94BEtvzhC9nyHP0rhno';
// const API_KEY = 'AIzaSyAkeXvgWkQ1Y966FTP-zdNBhW5qVKMTLFk';
// const API_KEY = 'AIzaSyCH253vDDuOjotKmqeeQ5RgQHsIYZM51Ug';
const CALLBACK_NAME = 'gmapsCallback';

let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;

const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

function init() {
  if (initialized) return initPromise;

  initialized = true;
  window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=${CALLBACK_NAME}`;
  script.onerror = rejectInitPromise;
  document.querySelector('head').appendChild(script);

  return initPromise;
}

function staticMap() {
  const img = `https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=380x132&maptype=satellite&key=${API_KEY}&format=png&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C`;
  return img;
}
exports.init = init;
exports.staticMap = staticMap;
