import request from '@/api/utils';

export function getFarmTotal(formdata = {}, method = 'post') {
  const url = '/api/farmdata/total';
  return request.Api(url, formdata, method);
}

export function getFarmDataMinute(formdata = {}, method = 'post') {
  const url = '/api/farmdata/minute';
  return request.Api(url, formdata, method);
}

export function getFarmDataMinutes(formdata = {}, method = 'post') {
  const url = '/api/farmdata/minutes';
  return request.Api(url, formdata, method);
}

export function getFarmMinuteGrup(formdata = {}, method = 'post') {
  const url = '/api/farmdata/minutegrup';
  return request.Api(url, formdata, method);
}

export function getFarmHourly(formdata = {}, method = 'post') {
  const url = '/api/farmdata/hourly';
  return request.Api(url, formdata, method);
}
