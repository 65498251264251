<template>
  <v-card
    cols="12"
    class="mt-0 pt-0 mb-0 pb-0"
  >
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          <div>
            <img
              style="display: block;
            -webkit-user-select: none;
            margin: auto;
            background-color: hsl(0, 0%, 90%);
            transition: background-color 300ms;
            min-height: 135px;
            "
              :src="mapUrl"
              @click="selectedFarmMap"
            >
          </div>
          <div class="d-flex">
            <div class="col-3 mt-0  pb-0 pt-0">
              Şirket:
            </div>

            <b> {{ farmdata.companyName }}</b>
          </div>
          <div class="d-flex">
            <div class="col-3 mt-0  pb-0 pt-0">
              Saha:
            </div>

            <b> {{ farmdata.farmName }}</b>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-card class="mt-0 pt-0 mb-0 pb-0">
      <v-data-table
        dense
        :headers="santralHeaders"
        :items="santralItems"
        hide-default-header
        hide-default-footer
        class="elevation-0"
      />
    </v-card>
    <Dialog
      v-model="dialog"
      width="1200px"
    >
      <Card
        width="1200px"
        height="100%"
        max-height="500px"
      >
        <Toolbar height="40px">
          <div class="col-12 d-flex">
            <v-spacer />
            <Button

              :min-width="'30px'"
              :small="true"
              :elevation="0"

              :button-style="{padding: '3px 3px 3px 3px'}"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </Button>
          </div>
        </Toolbar>
        <v-divider />

        <v-card-text>
          <div
            id="map"
          />
        </v-card-text>
      </card>
    </dialog>
  </v-card>
</template>

<script>
import moment from 'moment';
import { staticMap, init } from '@/utils/gmaps';

export default {

  data: () => ({
    santralItems: [],
    farmdata: {},
    google: null,
    map: null,
    dialog: false,
    mapUrl: '',
  }),
  computed: {
    santralHeaders() {
      return [
        { text: 'type', value: 'type' },
        { text: 'value', value: 'value' },
      ];
    },
  },
  created() {

  },
  mounted() {
    const self = this;
    // eslint-disable-next-line no-use-before-define
    const myVarİnv = setInterval(myTimerİnv, 1000);
    let k = 0;
    function myTimerİnv() {
      // eslint-disable-next-line no-plusplus
      k++;
      if (k > 30) {
        clearInterval(myVarİnv);
      }
      const { santralInverterData } = self.$store.getters;

      let inverterQuantity = 0;
      if (Object.keys(santralInverterData).length > 0) {
        clearInterval(myVarİnv);

        Object.keys(santralInverterData).forEach((item) => {
          inverterQuantity += Object.keys(santralInverterData[item]).length;
        });

        self.santralItems = [
          {
            type: 'İnverter Sayısı',
            value: 0,
          },
          {
            type: 'Combiner Sayısı',
            value: 0,
          },
          {
            type: 'Strings Sayısı',
            value: 0,
          },
          {
            type: 'Sensor Sayısı',
            value: 0,
          },
          {
            type: 'Güncelleme zamanı',
            value: moment().format('DD.MM.YYYY HH:mm:s'),
          },
        ];

        self.santralItems[0].value = inverterQuantity;
        self.getFarmdata();
      }
    }

    // eslint-disable-next-line no-use-before-define
    const myVarString = setInterval(myTimerString, 1000);
    function myTimerString() {
      const { santralStringboxData } = self.$store.getters;
      let combinerQuantity = 0;
      let stringsQuantity = 0;
      if (Object.keys(santralStringboxData).length > 0) {
        clearInterval(myVarString);

        Object.keys(santralStringboxData).forEach((s) => {
          combinerQuantity += Object.keys(santralStringboxData[s]).length;
          Object.keys(santralStringboxData[s]).forEach((item) => {
            if ('stringQuantity' in santralStringboxData[s][item]) {
              // eslint-disable-next-line radix
              stringsQuantity += parseInt(santralStringboxData[s][item].stringQuantity);
            }
          });
        });
        self.santralItems[1].value = combinerQuantity;
        self.santralItems[2].value = stringsQuantity;
      }
    }

    // eslint-disable-next-line no-use-before-define
    const myVarSensor = setInterval(myTimerSensor, 1000);

    function myTimerSensor() {
      const { santralSensorData } = self.$store.getters;

      let sensorQuantity = 0;
      if (Object.keys(santralSensorData).length > 0) {
        clearInterval(myVarSensor);
        Object.keys(santralSensorData).forEach((item) => {
          sensorQuantity += Object.keys(santralSensorData[item]).length;
        });
        self.santralItems[3].value = sensorQuantity;
      }
    }

    setInterval(this.santralgeneralupdate, 600000);

    setTimeout(() => {
      self.santralgeneralupdate();
    }, 2000);
  },
  methods: {
    getFarmdata() {
      const { farmlist } = this.$store.getters;
      if (Object.keys(farmlist).length > 0) {
        const storage = window.localStorage;
        const userCompany = JSON.parse(window.atob(storage.getItem('user_company')));

        Object.keys(farmlist).forEach((i) => {
          if (farmlist[i].companyCode === userCompany.companyCode) {
            if (farmlist[i].prefix === userCompany.prefix) {
              this.farmdata = farmlist[i];
              this.imgSourceFunc();
            }
          }
        });
      }
    },
    async imgSourceFunc() {
      const map = await staticMap();
      const { location } = this.farmdata;
      this.mapUrl = `${map}${location.lat},${location.lng}`;
      // return 'https://api.datasmarty.net/public/img/default.png';
      // return 'https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=600x400&maptype=satellite&key=AIzaSyAa6UpjtJskV8MVhBUe2xqyk5N3siWPLmw&format=png&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C40.530481,34.807014';
    },
    santralgeneralupdate() {
      if (this.santralItems.length > 0) {
        const { santralgeneralupdate } = this.$store.getters;
        this.santralItems[4].value = moment(santralgeneralupdate).format('DD.MM.YYYY HH:mm:s');
      }
    },
    async selectedFarmMap() {
      this.dialog = true;
      const self = this;
      this.google = await init();
      this.selectedFarmMap1(self);
    },
    async selectedFarmMap1(self) {
      const { google } = self;
      const { location } = self.farmdata;
      const options = {
        zoom: 15,
        center: {
          lat: location.lat,
          lng: location.lng,
        },
        gestureHandling: 'greedy', // yakınlaştırmayı mouse ile yapar
      };
      const map = new google.maps.Map(document.getElementById('map'), options);

      map.setCenter({
        lat: location.lat,
        lng: location.lng,
      });
      map.setZoom(15);

      const coords = {
        lat: location.lat,
        lng: location.lng,
      };

      const marker = new google.maps.Marker({
        position: coords,
        flat: true,
        format: 'png',
        maptype: 'satellite',
        animation: google.maps.Animation.DROP,
        optimized: false,
        visible: true,
        map,
      });
      google.maps.event.addListener(marker, 'click', () => {
        // eslint-disable-next-line no-param-reassign
        self.windowshow = true;
      });
    },
  },
};
</script>
<style scoped>
#map {
  height: 500px;
  width: 100%;
}

</style>
