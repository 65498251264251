<template>
  <v-card class="pt-2 pl-2 mr-1">
    <v-row class="my-2">
      <v-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="my-0 py-0"
      >
        <v-alert

          dense
          class="mr-5 "
          :color="alarmData.con_color"
          type="error"
          icon="mdi-checkbox-marked-circle"
        >
          Bağlantı : <strong>{{ alarmData.connection }}</strong>
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="my-0 py-0"
      >
        <v-alert
          dense
          class="mr-5  "
          :color="alarmData.er_color"
          type="error"
        >
          Hata : <strong>{{ alarmData.error }}</strong>
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="my-0 py-0"
      >
        <v-alert
          dense
          class="mr-5  "
          :color="alarmData.war_color"
          type="error"
          icon="mdi-alert-octagon"
        >
          Uyarı : <strong>{{ alarmData.warning }}</strong>
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="my-0 py-0 "
      >
        <notify-modal :dialog="alarmDialog" />
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="santralLive_header"
      :items="santralLive_items"
      hide-default-footer
      :items-per-page="20"
      class="elevation-1"
    >
      <template v-slot:item.connection="{item}">
        <div v-if="item.connection == 0">
          <i
            style="font-size: 20px"
            class="mdi mdi-checkbox-marked-circle green--text"
          />
        </div>

        <div v-else>
          <i
            style="font-size: 20px"
            class="mdi mdi-checkbox-marked-circle red--text"
          />
          {{ item.connection }}
        </div>
      </template>
      <template v-slot:item.error="{item}">
        <div v-if="item.error == 0">
          <i
            style="font-size: 20px"
            class="mdi mdi-alert grey--text"
          />
        </div>

        <div v-else>
          <i
            style="font-size: 20px"
            class="mdi mdi-alert red--text"
          />
          {{ item.error }}
        </div>
      </template>

      <template v-slot:item.warning="{item}">
        <div v-if="item.warning == 0">
          <i
            style="font-size: 20px"
            class="mdi mdi-alert-octagon grey--text"
          />
        </div>

        <div v-else>
          <i
            style="font-size: 20px"
            class="mdi mdi-alert-octagon orange--text"
          />
          {{ item.warning }}
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getSantralListLiveData } from '@/api/Dashboard/Santral';
import NotifyModal from '@/views/components/Notification/NotifyModal.vue';
import i18n from '@/locale';
import moment from 'moment';
import { getSantralLastpowerData } from '@/api/Request/santral';

export default {
  name: 'Dashboard',
  components: {
    NotifyModal,
  },
  data: () => ({
    santralLive_header: [],
    santralLive_items: [],
    alarmData: {},
    alarmDialog: false,
    windowsWidth: `${document.documentElement.clientWidth - 250}px`,
  }),
  computed: {},
  watch: {},
  created() {
    this.componentKey += 1;
  },
  mounted() {
    console.log('getSantralListLiveDatagetSantralListLiveData');
    setInterval(this.getSantralListLiveData, 300000);
    const self = this;

    // eslint-disable-next-line no-use-before-define
    const myVarİnv = setInterval(myTimerInv, 1000);

    function myTimerInv() {
      const santrals = self.$store.getters.santralRawData;
      console.log(santrals);
      if (Object.keys(santrals).length > 0) {
        clearInterval(myVarİnv);
        console.log('santrals');
        self.getSantralListLiveData();
      }
    }
  },

  methods: {
    getSantralListLiveData() {
      const formdata = {};
      const date = moment().format('YYYY-MM-DD');
      formdata.santral = {
        condiniton: {
          skip: 0,
          limit: 1,
          orderBys: [{ fieldName: 'datetimestamp', sortOrder: 'desc' }],
          count: '0',
          error: '1',
          connection: '1',
          warning: '1',
          date,
        },
      };
      console.log(formdata);
      this.santralLive_header = [
        { text: 'Santral', value: 'santral', width: '8%' },
        {
          text: `${i18n.t('NominalPlantPower')}(kWp)`,
          value: 'NominalPlantPower',
        },
        {
          text: `${i18n.t('IstantaneousPower')}(kW)`,
          value: 'IstantaneousPower',
        },
        {
          text: `${i18n.t('TheoreticalPower')}(kW)`,
          value: 'TheoreticalPower',
        },
        {
          text: `${i18n.t('PerformanceRatio')}(%)`,
          value: 'PerformanceRatio',
        },
        {
          text: `${i18n.t('DailyEnergys')}(kW)`,
          value: 'DailyEnergy',
        },
        { text: `${i18n.t('TotalEnergy')}(kW)`, value: 'TotalEnergy' },
        { text: i18n.t('general.updateTime'), value: 'date' },

        {
          text: i18n.t('general.connection'),
          value: 'connection',
          width: '5px',
        },

        { text: i18n.t('general.error'), value: 'error', width: '20 px' },
        { text: i18n.t('general.warning'), value: 'warning', width: '20 px' },
      ];
      console.log('getSantralListLiveData');
      console.log(this.santralLive_header);

      const measureList = [
        'NominalPlantPower',
        'IstantaneousPower',
        'TheoreticalPower',
        'PerformanceRatio',
        'DailyEnergy',
        'TotalEnergy',
      ];

      const { santralRawData } = this.$store.getters;

      // santralin en son gün i2utqx_santral_general_power collection dan
      // günlük enerji, PerformanceRatio,IstantaneousPower,TheoreticalPower en son verisi
      getSantralLastpowerData(formdata.santral).then((santralData) => {
        // eslint-disable-next-line no-undef
        const minuteData = getSantralListLiveData(
          santralData,
          santralRawData,
          measureList,
        );

        minuteData.then((res) => {
          this.alarmData = res.alarm;

          if (res.alarm.connection > 0) {
            this.alarmData.con_color = 'red';
          } else {
            this.alarmData.con_color = 'grey';
          }
          if (res.alarm.error > 0) {
            this.alarmData.er_color = 'red';
          } else {
            this.alarmData.er_color = 'grey';
          }
          if (res.alarm.warning > 0) {
            this.alarmData.war_color = 'orange';
          } else {
            this.alarmData.war_color = 'grey';
          }

          this.santralLive_items = res.liveData;
        });
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
