<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="3"
        xl="3"
        class="px-2"
      >
        <SantralInfo />
      </v-col>

      <v-col
        cols="12"
        md="8"
        lg="9"
        xl="9"
        class="px-4 py-3 "
      >
        <v-row>
          <v-col
            cols="12"
            class="px-1 py-2"
          >
            <InstantDataGauge />
          </v-col>
        </v-row>
        <v-row class="  ">
          <v-col
            cols="12"
            md="12"
            lg="9"
            xl="9"
            class="px-1 py-2"
          >
            <SantralStats />
          </v-col>
          <v-col
            cols="12"
            md="12"
            lg="3"
            xl="3"
            class="px-0 py-2   "
          >
            <Weather />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="px-2 py-1"
      >
        <Card
          width="100%"
          min_height="280px"
        >
          <DailyMinuteData />
        </Card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="px-2 py-0 "
      >
        <SantralList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InstantDataGauge from '@/views/components/Dashboard/InstantDataGauge.vue';
import SantralList from '@/views/components/Dashboard/SantralList.vue';
import DailyMinuteData from '@/views/components/Dashboard/DailyMinuteData.vue';
import SantralStats from '@/views/components/Dashboard/SantralStats.vue';
import SantralInfo from '@/views/components/Dashboard/SantralInfo.vue';
import Weather from '@/views/components/Dashboard/Weather.vue';

export default {
  name: 'Dashboard',
  components: {
    InstantDataGauge,
    SantralList,
    DailyMinuteData,
    SantralStats,
    SantralInfo,
    Weather,
  },
  data: () => ({
    santralmodel: 'null',
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
