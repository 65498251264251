<template>
  <v-col
    class="mt-0 mb-3"
  >
    <v-row>
      <v-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="pr-3 "
      >
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="startDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{on, attrs}">
            <v-text-field
              v-model="startDate"
              label="Tarih"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            scrollable
          >
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Kapat
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(startDate)"
            >
              Seç
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="pr-3  "
      >
        <Select
          v-model="chartTypeModel"
          :items="chartType"
          label="Grafik"
          :hide-details="true"
          outlined
          dense
        />
      </v-col>

      <v-col
        sm="3"
        md="2"
        lg="1"
        xl="1"
        class="pr-3  mx-2"
      >
        <Button
          color="primary"
          @click="getSantralDataChart"
        >
          Filtrele
        </Button>
      </v-col>
    </v-row>

    <v-col
      cols="12"
      class="mt-0 pt-0 pb-0 mb-0"
    >
      <echart
        id="general-santral-power-daily-dash"
        class-name="widget-content"
        height="350px"
        :width="windowsWidth"
        :chart-data="chartData"
      />
    </v-col>
  </v-col>
</template>

<script>
import Echart from '@/views/components/Echart/Chart.vue';
import moment from 'moment';
import i18n from '@/locale';
import { getFarmMinuteGrup } from '@/api/Request/farmdata';
import { setChartMinuteGraph } from '@/api/ChartComponent/chartComp';

export default {
  name: 'Dashboard',
  components: {
    Echart,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['farmdata'],
  data: () => ({
    componentKey: 1,
    chartData: {},
    chartTypeModel: 'line2',
    startDate: moment().format('YYYY-MM-DD'),
    finishDate: moment().format('YYYY-MM-DD'),
    chartType: ['line', 'bar', 'line2'],
    menu: false,
    modal: false,
    menu2: false,
    riseze: 'false',
    measures: [],
    windowsWidth: '100%',
  }),
  computed: {},
  watch: {
    farmdata: {
      handler() {
        this.getSantralDataChart();
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      this.getSantralDataChart();
    }, 1000);
    setInterval(this.getSantralDataChart, 300000);
  },
  methods: {

    getSantralDataChart() {
      this.windowsWidth = `${document.documentElement.clientWidth - 250}px`;
      if (this.farmdata) {
        this.windowsWidth = this.farmdata.windowsWidth;
      }
      this.windowsWidth -= 200;

      this.finishDate = moment(this.startDate)
        .add(1, 'day')
        .format('YYYY-MM-DD');

      const measures = [];
      const measureList = [];
      // eslint-disable-next-line no-underscore-dangle
      const _measureList = [];
      const { statsmeasureList } = this.$store.getters;
      const mList = ['IstantaneousPower', 'PerformanceRatio', 'TheoreticalPower'];
      const tempmeasureList = {};
      Object.keys(statsmeasureList).forEach((i) => {
        if (mList.indexOf(statsmeasureList[i].value) > -1) {
          const obj = {
            value: statsmeasureList[i].value,
            text: i18n.t(statsmeasureList[i].value),
            type: statsmeasureList[i].type,
            unit: statsmeasureList[i].unit,
          };
          tempmeasureList[statsmeasureList[i].value] = obj;
        }
      });
      const liveMeasureList = [];
      mList.forEach((m) => {
        liveMeasureList.push(tempmeasureList[m]);
      });

      const energy = ['DailyEnergy', 'TotalEnergy'];

      liveMeasureList.forEach((item) => {
        if (energy.indexOf(item.value) < 0) {
          measures.push(item.value);
          measureList.push(item);
        }
      });

      liveMeasureList.forEach((item) => {
        if (energy.indexOf(item.value) < 0) {
          _measureList.push(item.value);
        }
      });

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          measures: _measureList,
        },
      };

      if (this.farmdata) {
        params.condiniton.companyCode = this.farmdata.companyCode;
        params.condiniton.prefix = this.farmdata.prefix;
      }

      getFarmMinuteGrup(params).then((r) => {
        this.chartData = setChartMinuteGraph(r, liveMeasureList, '', this.chartTypeModel);
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
