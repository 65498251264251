import helper from '../helper';

const getMinuteLabel = (item) => {
  if (helper.is_Empty(item)) { return false; }
  if (helper.is_Empty(item.Hour)) { return false; }
  let hh;
  let mm;
  item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
  item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
  return `${hh}:${mm}`;
};

const getHourLabel = (item) => {
  if (item === undefined) { return false; }

  let hh;
  item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
  return `${hh}`;
};

const getDailyLabel = (item) => {
  if (item === undefined) { return false; }
  let m; let
    d;
  item.Month < 10 ? (m = `0${item.Month}`) : (m = `${item.Month}`);
  item.Day < 10 ? (d = `0${item.Day}`) : (d = `${item.Day}`);
  return `${item.Year}-${m}-${d}`;
};

const getWeeklyLabel = (item) => {
  if (item === undefined) { return false; }
  return `Hafta-${item.Week}`;
};

const getMonthlyLabel = (item) => {
  if (item === undefined) { return false; }
  let m;
  item.Month < 10 ? (m = `0${item.Month}`) : (m = `${item.Month}`);
  return `${item.Year}-${m}`;
};

const getMonthlyString = (item) => {
  if (item === undefined) { return false; }

  return `${item.Year} ${monthLabel(item.Month)}`;
};

const getYearlyLabel = (item) => {
  if (item === undefined) { return false; }
  return item.Year;
};

const monthLabel = (i) => {
  const months = [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ];

  return months[i];
};

export default {
  monthLabel,
  getMinuteLabel,
  getHourLabel,
  getDailyLabel,
  getWeeklyLabel,
  getMonthlyLabel,
  getMonthlyString,
  getYearlyLabel,
};
